import { basicRequiredRule } from '@/template/rule'
import Validator from '@/validators'

export const baseNewFormConfig = {
  formItems: [
    {
      field: 'value',
      type: 'imgUpload',
      label: 'terminal.logo-file',
      otherOptions: {
        maxlength: 1000,
        uploadApiAction: 'system/uploadImgFile'
      },
      colLayout: {
        xl: 24,
        lg: 24,
        md: 24,
        sm: 24,
        xs: 24
      }
    },
    {
      field: 'key',
      type: 'input',
      label: 'terminal.brand-name',
      otherOptions: {
        maxlength: 100
      }
    },

    {
      field: 'order',
      type: 'input',
      label: 'terminal.order',
      otherOptions: {
        maxlength: 3
      }
    },

    {
      field: 'description',
      type: 'input',
      label: 'general.description',

      otherOptions: {
        maxlength: 500
      }
    }
  ],
  validateRules: {
    value: [basicRequiredRule],
    key: [basicRequiredRule],
    order: [
      { validator: Validator.integer, message: 'validator.must-be-integer' }
    ]
  }
}
