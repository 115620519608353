export const baseViewFormConfig = {
  formItems: [
    {
      field: 'value',
      type: 'imgUpload',
      label: 'terminal.logo-file',
      colLayout: {
        xl: 24,
        lg: 24,
        md: 24,
        sm: 24,
        xs: 24
      },
      otherOptions: {
        disabled: true,
        uploadApiAction: 'system/uploadImgFile'
      }
    },
    {
      field: 'key',
      type: 'input',
      label: 'terminal.brand-name',
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'order',
      type: 'input',
      label: 'terminal.order',
      otherOptions: {
        disabled: true
      }
    },

    {
      field: 'description',
      type: 'input',
      label: 'general.description',

      otherOptions: {
        maxlength: 500,
        disabled: true
      }
    }
  ]
}
